<template>
  <div class="home md:p-8">
    <img src="/img/1179249_McCannes_Desktop.jpg" alt="Cannes" class="sm:block md:hidden">

    <form v-if="submitStatus !== 'SUCCESS'" @submit.prevent="handleSubmit" class="px-4">

      <h2>Confirm your place at the next best thing to Cannes. Probably.</h2>
      <h3>Where:</h3>
      <p class="subhead">McCann Birmingham, Solihull, B90 4WE</p>
      <h3>When:</h3>
      <p class="subhead">Tuesday 11<sup>th</sup> June 2019</p>
      <p class="details">Expert masterclasses: <span class="whitespace-no-wrap">2pm – 4pm</span></p>
      <p class="details">Creative review and Cannes Lions predictions, drinks and canapés: <span class="whitespace-no-wrap">4pm – 6pm</span></p>
      <p class="details">Fill out the fields below to let us know that you’re interested in an afternoon of creativity, inspiration and just a hint of competition.</p>
      <text-field
        name="firstName"
        v-model.trim.lazy="$v.formData.firstName.$model"
        labelText="First name"
        outerClasses="w-full md:w-1/2 md:pr-4 pt-8"
        :error="errors($v.formData.firstName)"
        errorClasses="text-red"
      />
      <text-field
        name="lastName"
        v-model.trim.lazy="$v.formData.lastName.$model"
        labelText="Last name"
        outerClasses="w-full md:w-1/2 md:pl-4 pt-8"
        :error="errors($v.formData.lastName)"
        errorClasses="text-red"
      />
      <text-field
        name="email"
        v-model.trim.lazy="$v.formData.email.$model"
        labelText="Email address"
        outerClasses="w-full pt-8"
        :error="errors($v.formData.email)"
        errorClasses="text-red"
        @input="delayTouch($v.formData.email)"
      />
      <label for="requireAccommodation" class="flex items-start text-left pt-8">
        <input
          id="requireAccommodation"
          type="checkbox"
          v-model.lazy="$v.formData.accommodation.$model"
          class="flex-0"
          @change="$v.formData.accommodation.$touch()"
        />
        <span class="flex-1 ml-4">If you are interested in accommodation for this event, please tick here</span>
      </label>
      <label for="acceptTerms" class="flex items-start text-left pt-8 flex-wrap">
        <input
          id="acceptTerms"
          type="checkbox"
          v-model.lazy="$v.formData.terms.$model"
          class="flex-0"
          @change="$v.formData.terms.$touch()"
        />
        <span class="flex-1 ml-4">
          By ticking here, you are confirming that you are happy to be contacted by McCann Central in relation to this event
          <span class="error w-full block mt-2 text-red" v-if="submitAttempted && !formData.terms">Required</span>
        </span>

      </label>
      <div class="w-full py-8">
        <p
          v-if="formError !== '' && $v.$invalid"
          class="text-red mb-4"
        >{{ formError }}</p>
        <p
          v-if="submitError && submitStatus === 'ERROR'"
          class="text-red mb-4"
        >{{ submitError }}</p>
        <button
          type="submit"
          class="mx-auto relative overflow-hidden py-4 px-16 text-xl font-bold text-white bg-black"
          :disabled="submitStatus === 'PENDING'"
        ><span>Submit <img alt="chevron" src="/img/chevron.png"></span></button>
      </div>
    </form>
    <div v-if="submitStatus === 'SUCCESS'">
      <h2>Thank you for confirming</h2>
      <p>That’s it, you’re all signed up for the only Cannes Lions pre-event (in Birmingham).</p>
      <p>We’ll be in touch shortly with a taste of what is to come on the day, and with details of how to book your place at the expert masterclasses.</p>
      <p>In the meantime, we suggest downloading the invite below to make sure it’s in your diary – this is a date not to be missed.</p>
      <add-to-calendar />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { required, alpha, email } from 'vuelidate/lib/validators'

import TextField from '@/components/TextField'
import AddToCalendar from '@/components/AddToCalendar'

import {
  getValidationMessage,
  validationMessages
} from '@/helpers/vuelidate-messages'

const validations = {
  formData: {
    firstName: {
      required,
      alpha
    },
    lastName: {
      required,
      alpha
    },
    email: {
      required,
      email
    },
    terms: {
      required,
      sameAs: true
    },
    accommodation: {}
  }
}

const touchMap = new WeakMap()

export default {
  name: 'home',

  computed: {
    isBrowser () {
      return process.browser
    }
  },

  data () {
    return {
      page: null,
      submitStatus: 'IDLE',
      submitAttempted: false,
      formError: '',
      submitError: '',
      formData: {
        firstName: '',
        lastName: '',
        email: '',
        segment: '',
        terms: false,
        accommodation: false
      }
    }
  },

  head: {
    title: {
      inner: 'Join us for an evening of insight, awards and Truth Well Told – McCannes'
    }
  },

  mounted () {
    let query = Object.assign({}, this.$route.query)
    const queryKeys = Object.keys(query)
    queryKeys.forEach(key => {
      if (this.formData.hasOwnProperty(key)) {
        this.formData[key] = query[key]
      }
      delete query[key]
    })
    this.$router.replace({ query })
  },

  validations,

  methods: {
    errors: getValidationMessage(validationMessages)(validations),

    handleSubmit () {
      this.$v.$touch()
      this.submitAttempted = true
      this.submitStatus = 'PENDING'
      if (this.$v.$invalid || !this.formData.terms) {
        this.submitStatus = 'IDLE'
        this.formError = 'Please fix the problems with your submission and try again.'
      } else {
        this.submitStatus = 'IDLE'
        this.formError = ''
        axios.post('/.netlify/functions/submitDetails', this.formData)
          .then(response => {
            this.submitStatus = 'SUCCESS'
          })
          .catch(error => {
            console.error(error)
            this.submitStatus = 'ERROR'
            this.submitError = 'There was a problem submitting your data. Please try again.'
          })
      }
    },

    delayTouch ($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 500))
    }
  },

  components: {
    TextField,
    AddToCalendar
  }
}
</script>

<style scoped>
.home {
  max-width: 680px;
  @apply mx-auto bg-white;
}
img {
  @apply w-full mb-4;
}
h2 {
  @apply w-full text-center mt-8 mb-6 uppercase tracking-normal leading-tight;
}
h3 {
  @apply font-normal text-xl block w-full uppercase tracking-normal;
}
p {
  @apply leading-normal text-center w-full py-2 mb-4;
}
.subhead {
  @apply uppercase;
}
.details {
  @apply mb-0;
}
form {
  @apply flex flex-wrap;
}
label {
  @apply leading-normal;
}
[type="checkbox"] {
  @apply mt-1;
}
button span {
  @apply block relative uppercase;
  z-index:2;
}
button img {
  @apply inline-block m-0 bg-white rounded-full;
  width: 15px;

}
button::after {
  content: '';
  z-index: 1;
  width: 1px;
  height: 1px;
  line-height: 1px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @apply block absolute bg-grey-darkest rounded-full opacity-0;
  transition: opacity .01s ease-out, all .3s ease-out;
}

button:hover::after,
button:focus::after {
  @apply opacity-100;
  width: 20rem;
  height: 20rem;
  transition: opacity .01s .29s ease-in, all .3s ease-in;
}
button:disabled {
  opacity: .25;
}
</style>
