<template>
<div class="form-field" :class="outerClasses">
  <div class="field-wrapper" :class="innerClasses">
    <input
      v-bind="$attrs"
      :id="id"
      :name="name"
      :value="value"
      :class="fieldClasses"
      ref="field"
      @input="updateSelf($event.target.value.trim())"
      :v-on="listeners"
      @focus="(e) => {
        touch()
        if (this.$listeners.focus) {
          this.$listeners.focus(e)
        }
      }"
      @blur="(e) => {
        handleBlur()
        if (this.$listeners.blur) {
          this.$listeners.blur(e)
        }
      }"
      placeholder=" "
    />
    <label :for="id">{{ labelText }}</label>
  </div>
  <span class="error" :class="errorClasses">{{ error }}</span>
</div>
</template>

<script>
import reject from 'lodash'

export default {
  model: {
    prop: 'value',
    event: 'input'
  },

  data () {
    return {
      id: null,
      touched: false,
      isActive: false
    }
  },

  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ''
    },
    labelText: {
      type: String,
      required: true
    },
    fieldClassesAlways: {
      type: String,
      default: ''
    },
    fieldClassesActive: {
      type: String,
      default: 'border-blue'
    },
    fieldClassesClean: {
      type: String,
      default: 'text-black'
    },
    fieldClassesError: {
      type: String,
      default: ''
    },
    labelClassesAlways: {
      type: String,
      default: ''
    },
    labelClassesClean: {
      type: String,
      default: ''
    },
    labelClassesError: {
      type: String,
      default: ''
    },
    outerClasses: {
      type: String,
      default: ''
    },
    innerClassesAlways: {
      type: String,
      default: ''
    },
    innerClassesActive: {
      type: String,
      default: 'border-blue text-blue'
    },
    innerClassesClean: {
      type: String,
      default: 'border-green text-green'
    },
    innerClassesError: {
      type: String,
      default: 'border-red text-red'
    },
    errorClasses: {
      type: String,
      default: 'text-red'
    },
    error: {
      type: String,
      default: ''
    }
  },

  computed: {
    listeners () {
      return {
        ...reject(this.$listeners, (item, key) => (key === 'focus' || key === 'blur'))
      }
    },

    innerClasses () {
      let classes = this.innerClassesAlways

      if (this.isActive) {
        classes += ' is-active'
        if (!this.value.length && (!this.error || !this.error.length)) {
          classes += ' ' + this.innerClassesActive
        }
      }
      if (this.error && this.error.length) {
        classes += ' ' + this.innerClassesError + ' has-error'
      } else if ((!this.error || !this.error.length) && this.value.length) {
        classes += ' ' + this.innerClassesClean
      }
      return classes
    },

    fieldClasses () {
      let classes = this.fieldClassesAlways

      if (this.isActive) {
        classes += ' is-active'
        if (!this.value.length && (!this.error || !this.error.length)) {
          classes += ' ' + this.fieldClassesClean
        }
      }
      if (this.error && this.error.length) {
        classes += ' ' + this.fieldClassesError + ' has-error'
      } else if ((!this.error || !this.error.length) && this.value.length) {
        classes += ' ' + this.fieldClassesClean
      }
      return classes
    }
  },

  mounted () {
    this.id = this._uid
  },

  methods: {
    touch () {
      this.touched = true
      this.isActive = true
    },

    handleBlur () {
      this.isActive = false
    },

    updateSelf (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
.form-field {
  @apply relative;
}
.field-wrapper {
  @apply relative overflow-visible border-solid border-0 border-b;
}
.field-wrapper::after {
  content: '';
  @apply block absolute w-px h-px;
  background-color:transparent;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all .3s ease-out;
}
.field-wrapper.is-active::after {
  @apply w-full;
  background-color: currentColor;
  transition: all .3s ease-in;
}

label {
  @apply block absolute z-10 pt-2 text-xl pointer-events-none;
  top:0;
  transition: all .15s ease-out;
}

input {
  @apply block relative z-0 w-full py-2 px-0 text-xl border-0 outline-none;
}
.field-wrapper:focus-within label,
input:not(:placeholder-shown) + label {
  @apply pt-0 -mt-4 text-sm;
  transition: all .15s ease-in;
}
.error {
  @apply text-left block w-full mt-2 text-base
}
</style>
