const isFn = x => typeof x === 'function'

const plural = (words, count) => {
  words = words.split('|').map(x => x.trim())
  return count > 1 ? words[2].replace('{n}', count) : words[count]
}

// Given a Vuelidate validations object, find the validator keys
export function extractValidatorKeys (validations, validators = []) {
  const keys = Object.keys(validations)
  validators.push(...keys.filter(x => isFn(validations[x])))
  keys
    .filter(x => !isFn(validations[x]))
    .forEach(x => extractValidatorKeys(validations[x], validators))
  return validators
}

export const getValidationMessage = messages => validations => {
  let keys = extractValidatorKeys(validations)
  // check to make sure all validators have corresponding error messages
  const missing = keys.filter(x => !(x in messages))
  if (missing.length) {
    console.warn(`Validators missing validation messages: ${missing.join(', ')}`)
    keys = keys.filter(x => missing.indexOf(x) < 0)
  }
  const keyLen = keys.length

  // Vue component method
  // Given a vuelidate field object, maybe return an error messsage
  return function (field) {
    if (!field.$dirty) return null
    let key
    for (let i = 0; i < keyLen; i++) {
      key = keys[i]
      if (field[key] === false) return messages[key](field.$params[key])
    }
    return null
  }
}

export const validationMessages = {
  required: () => 'Required',
  alpha: () => 'Please enter a valid name, letters only',
  email: () => 'Please enter a valid email address',
  txtMinLen: params => {
    const min = plural(
      'no characters | one character | {n} characters',
      params.min
    )
    return `Must be at least ${min}`
  }
}

// ***************************************************************
// Usage Example
// ***************************************************************
// <template>
//   <div>
//     <input v-model="name" />
//     <span>{{errors($v.name)}}</span>
//   </div>
// </template>

// <script>
// import { required, minLength } from 'vuelidate/lib/validators'

// const validations = {
//   name: {
//     required,
//     txtMinLen: minLength(2)
//   }
// }
//
// export default {
//   data () {
//     return { name: '' }
//   },
//   validations,
//   methods: {
//     errors: getValidationMessage(validationMessages)(validations)
//   }
// }
// </script>
