import Vue from 'vue'
import VueHead from 'vue-head'
import Vuelidate from 'vuelidate'
import './assets/tailwind.css'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

Vue.use(VueHead)
Vue.use(Vuelidate)

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event('x-app-rendered'))
}).$mount('#app')
