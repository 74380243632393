import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pages: {
      pageData: {

      }
    }
  },
  mutations: {
    change (state, data) {
      state[data.property] = data
    }
  },
  actions: {
    async receivePageData ({ commit }, data) {
      if (data && data.path) {
        await commit('setPageData', {
          property: 'pages.pageData',
          data: data
        })
      }
    }
  }
})
