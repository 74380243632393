var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-field",class:_vm.outerClasses},[_c('div',{staticClass:"field-wrapper",class:_vm.innerClasses},[_c('input',_vm._b({ref:"field",class:_vm.fieldClasses,attrs:{"id":_vm.id,"name":_vm.name,"v-on":_vm.listeners,"placeholder":" "},domProps:{"value":_vm.value},on:{"input":function($event){_vm.updateSelf($event.target.value.trim())},"focus":function (e) {
        _vm.touch()
        if (this$1.$listeners.focus) {
          this$1.$listeners.focus(e)
        }
      },"blur":function (e) {
        _vm.handleBlur()
        if (this$1.$listeners.blur) {
          this$1.$listeners.blur(e)
        }
      }}},'input',_vm.$attrs,false)),_c('label',{attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.labelText))])]),_c('span',{staticClass:"error",class:_vm.errorClasses},[_vm._v(_vm._s(_vm.error))])])}
var staticRenderFns = []

export { render, staticRenderFns }